<template>
  <div class="wrapper">
    <div class="mb-4">
      <h3>리더십 진단 관리​</h3>
    </div>

    <article>
      <div class="white-box">
        <table class="table table-hover text-center mb-4">
          <thead>
            <tr>
              <th>No</th>
              <th>고객사명</th>
              <th>진단명(노출용)</th>
              <th>진단명(내부용)</th>
              <th>진단 기간</th>
              <th>진단 문항 수</th>
              <th>진단 대상자 수</th>
              <th>진단 현황</th>
              <th>등록일</th>
              <th>HQ매니저</th>
            </tr>
          </thead>
          <tbody class="table-group-divider">
            <tr v-if="process.isLoading">
              <td colspan="10">Loading ...</td>
            </tr>
            <tr v-if="!process.isLoading && process.groupList.length === 0">
              <td colspan="10">정보가 없습니다.</td>
            </tr>
            <tr v-else-if="!process.isLoading" v-for="group in process.groupList" :key="group.idx">
              <td>
                <p>
                  {{ group.rowNum }}
                </p>
              </td>
              <td>
                <p>{{ group.companyName }}</p>
              </td>
              <td>
                <p class="text-primary text-underline cursor" @click="goEdit(group.idx)">
                  {{ group.groupNameShow }}
                </p>
              </td>
              <td>
                <p>{{ group.groupName }}</p>
              </td>
              <td>
                <p>{{ group.startDate }} ~ {{ group.endDate }}</p>
              </td>
              <td>
                <p>{{ group.questionCnt === 0 ? '-' : group.questionCnt }}</p>
              </td>
              <td>
                <p>{{ group.diagSubjectCnt === 0 ? '-' : group.diagSubjectCnt }}</p>
              </td>
              <td>
                <p class="text-primary text-underline cursor" @click="goDetail(group.idx)">
                  {{ group.diagCompleteCnt === 0 ? '-' : group.diagCompleteCnt }}/{{ group.diagListCnt === 0 ? '-' : group.diagListCnt }}
                </p>
              </td>
              <td>
                <p>{{ group.createDate }}</p>
              </td>
              <td>
                <p>{{ group.creatorName }}</p>
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <Paging :totaldata="process.pagination.total" :pagingdata="process.params.pageSize" :pagingrange="5" :setPage="process.params.pageNum" @onPagingEvent="process.movePage"></Paging>
        </div>
      </div>
    </article>
  </div>
</template>

<script setup>
import { onMounted } from '@vue/runtime-core';
import { ref, reactive } from '@vue/reactivity';
import { _axios } from '@/utils/axios';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();
const store = useStore();

const goEdit = idx => {
  router.push(`/GenerateDiagnosisCreate?type=edit&idx=${idx}`);
};

const goDetail = idx => {
  router.push(`/DiagnosisManagementDetail?&idx=${idx}`);
};

const process = reactive({
  isLoading: true,
  params: {
    pageNum: 1,
    pageSize: 50,
  },
  pagination: {
    total: 0,
  },
  // 진단 관리 리스트 페이지 데이터
  groupList: [],

  getGroupList: async () => {
    process.isLoading = true;

    const result = await _axios.get(`/admin/group/list?pageNum=${process.params.pageNum}&pageSize=${process.params.pageSize}`, {});

    const { success, data, message } = result.data;

    if (success) {
      process.groupList = data.list;
      process.pagination.total = data.total;
    } else {
      alert(message);
    }

    process.isLoading = false;
  },

  movePage: pageNumber => {
    process.params.pageNum = pageNumber;
    process.getGroupList();
  },
});

onMounted(() => {
  process.getGroupList();
});
</script>

<style lang="scss" scoped></style>
